import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    routeMapLinkStyle: {
        width: '100%'
    },
    container: {
        minHeight: 445,
        maxWidth: 450,
        minWidth: 300,
        [theme.breakpoints.down('sm')]: {
            minHeight: 380,
            minWidth: 280
        },
        height: '100%',
        cursor: 'pointer',
        borderRadius: '20px',
        backgroundColor: theme.palette.bg.white,
        border: `1px solid ${theme.palette.fg.silver}`,
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            transition: 'all 0.3s ease',
            border: `1px solid ${theme.palette.fg.black}`
        }
    },
    companyCardImage: {
        position: 'relative',
        width: '100%',
        height: '122px',
        [theme.breakpoints.up('sm')]: {
            height: '184px'
        }
    },
    cardMedia: {
        height: '100%',
        width: '100%',
        borderRadius: '20px 20px 0px 0px',
        objectFit: 'cover'
    },
    companyLogo: {
        position: 'absolute',
        overflow: 'hidden',
        left: '20px',
        bottom: '0px',
        transform: 'translateY(50%)',
        width: '77px',
        height: '77px',
        border: `2px solid ${theme.palette.fg.silver}`,
        borderRadius: '50%',
        backgroundColor: theme.palette.bg.white,
        [theme.breakpoints.up('sm')]: {
            width: '70px',
            height: '70px'
        },
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    },
    companyInfoContainer: {
        width: '100%',
        flex: 1,
        padding: '55px 23px 18px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            padding: '45px 23px 22px'
        }
    },
    upContainer: {
        width: '100%'
    },
    companyName: {
        paddingBottom: '9px',
        color: theme.palette.fg.black,
        fontSize: '22px',
        lineHeight: '25px',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '12px',
            fontSize: '20px',
            lineHeight: '23px'
        }
    },
    chips: {
        paddingBottom: '15px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '6px'
    },
    chipRoot: {
        height: 'fit-content',
        width: 'fit-content',
        borderRadius: '50px',
        padding: '2px 10px',
        backgroundColor: theme.palette.bg.greyLight
    },
    chipLabel: {
        padding: 0,
        fontFamily: 'GreedStandard-Medium',
        color: theme.palette.fg.black,
        fontSize: '10px',
        lineHeight: '18px'
    },
    companyDescription: {
        display: '-webkit-box',
        lineClamp: '3',
        boxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.fg.black,
        fontSize: 16,
        lineHeight: '22px',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
            lineHeight: '20px'
        }
    },
    downContainer: {
        width: '100%'
    },
    peopleWantedContainer: {
        width: '100%',
        display: 'flex',
        gap: '18px'
    },
    peopleWanted: {
        display: 'flex',
        flexDirection: 'column',
        columnGap: '3px',
        alignItems: 'center',
        [theme.breakpoints.up(412)]: {
            flexDirection: 'row'
        },
        [theme.breakpoints.up('sm')]: {
            columnGap: '8px'
        }
    },
    peopleWantedText: {
        color: theme.palette.fg.black40Opacity,
        textTransform: 'lowercase',
        fontSize: '12px',
        lineHeight: '15px',
        textAlign: 'center'
    }
}))
