import React from 'react'
import LeftLongArrowSVG from './svgs/LeftLongArrowSVG'
import RightLongArrowSVG from './svgs/RightLongArrowSVG'
import Package0SVG from './svgs/Package0SVG'
import Package1SVG from './svgs/Package1SVG'
import Package2SVG from './svgs/Package2SVG'
import PackageSpaceshipSVG from './svgs/PackageSpaceshipSVG'
import KiteSVG from './svgs/KiteSVG'
import PackageTelescopeSVG from './svgs/PackageTelescopeSVG'
import PackageRocketSVG from './svgs/PackageRocketSVG'
import PackageSaucerSVG from './svgs/PackageSaucerSVG'
import PackageBalloonSVG from './svgs/PackageBalloonSVG'
import Balloon3SVG from './svgs/Balloon3SVG'
import ZeppelinSVG from './svgs/ZeppelinSVG'
import PackagePlaneSVG from './svgs/PackagePlaneSVG'
import InternshipStatSVG from './svgs/InternshipStatSVG'
import FirstJobStatSVG from './svgs/FirstJobStatSVG'
import StudentJobStatSVG from './svgs/StudentJobStatSVG'
import EnterpriseStatSVG from './svgs/EnterpriseStatSVG'
import VisitStatSVG from './svgs/VisitStatSVG'
import NewJobStatSVG from './svgs/NewJobStatSVG'
import CandidacyStatSVG from './svgs/CandidacyStatSVG'
import FacebookSVG from './svgs/FacebookSVG'
import InstagramSVG from './svgs/InstagramSVG'
import LinkedinSVG from './svgs/LinkedinSVG'
import LightningSVG from './svgs/LightningSVG'
import GraduateSVG from './svgs/GraduateSVG'
import FirstJobSVG from './svgs/FirstJobSVG'
import TraineeSVG from './svgs/TraineeSVG'
import TiktokSVG from './svgs/TiktokSVG'
import RestaurantSVG from './svgs/RestaurantSVG'
import TotebagShoppingSVG from './svgs/TotebagShoppingSVG'
import BookSVG from './svgs/BookSVG'
import VegetablesSVG from './svgs/VegetablesSVG'
import EventPaidSVG from './svgs/EventPaidSVG'
import LateNightSVG from './svgs/LateNightSVG'
import TempWorkSVG from './svgs/TempWorkSVG'
import UmbrellaBeachSVG from './svgs/UmbrellaBeachSVG'
import LocationSVG from './svgs/LocationSVG'
import TechnologySVG from './svgs/TechnologySVG'
import SettingsSVG from './svgs/SettingsSVG'
import ToolboxSVG from './svgs/ToolboxSVG'
import AccountingSVG from './svgs/AccountingSVG'
import SpeakSVG from './svgs/SpeakSVG'
import LawSVG from './svgs/LawSVG'
import MoneySVG from './svgs/MoneySVG'
import EarthSVG from './svgs/EarthSVG'
import PublicInstitutionSVG from './svgs/PublicInstitutionSVG'
import DrivePlanSVG from './svgs/DrivePlanSVG'
import StarryEyesSVG from './svgs/StarryEyesSVG'
import CalendarSVG from './svgs/CalendarSVG'
import ImpactSVG from './svgs/ImpactSVG'
import TechnicalSVG from './svgs/TechnicalSVG'
import MedecineSVG from './svgs/MedecineSVG'
import ClassicPlanSVG from './svgs/ClassicPlanSVG'
import EnvelopeSVG from './svgs/EnvelopeSVG'
import PersonSVG from './svgs/PersonSVG'
import PlaySVG from './svgs/PlaySVG'
import HeartSVG from './svgs/HeartSVG'
import PsychologySVG from './svgs/PsychologySVG'
import CleaningSVG from './svgs/CleaningSVG'
import ImageSVG from './svgs/ImageSVG'
import LightBulbSVG from './svgs/LightBulbSVG'
import ThumbsUpSVG from './svgs/ThumbsUpSVG'
import LaughSVG from './svgs/LaughSVG'
import FireSVG from './svgs/FireSVG'
import RollingEyesSVG from './svgs/RollingEyesSVG'

const getPath = (name, props) => {
    switch (name) {
    case 'left-long-arrow': return <LeftLongArrowSVG {...props}/>
    case 'right-long-arrow': return <RightLongArrowSVG {...props}/>
    case 'package-0': return <Package0SVG {...props}/>
    case 'package-1': return <Package1SVG {...props}/>
    case 'package-2': return <Package2SVG {...props}/>
    case 'package-3':
    case 'package-spaceship': return <PackageSpaceshipSVG {...props}/>
    case 'package-4':
    case 'kite': return <KiteSVG {...props}/>
    case 'package-telescope': return <PackageTelescopeSVG {...props}/>
    case 'package-rocket': return <PackageRocketSVG {...props}/>
    case 'package-saucer': return <PackageSaucerSVG {...props}/>
    case 'package-balloon': return <PackageBalloonSVG {...props}/>
    case 'package-balloon-3':
    case 'balloon-3': return <Balloon3SVG {...props}/>
    case 'package-blimp':
    case 'zeppelin': return <ZeppelinSVG {...props}/>
    case 'package-plane': return <PackagePlaneSVG {...props}/>
    case 'internship-stat': return <InternshipStatSVG {...props}/>
    case 'first-job-stat': return <FirstJobStatSVG {...props}/>
    case 'student-job-stat': return <StudentJobStatSVG {...props}/>
    case 'enterprise-stat': return <EnterpriseStatSVG {...props}/>
    case 'visit-stat': return <VisitStatSVG {...props}/>
    case 'new-job-stat': return <NewJobStatSVG {...props}/>
    case 'candidacy-stat': return <CandidacyStatSVG {...props}/>
    case 'facebook': return <FacebookSVG {...props}/>
    case 'instagram': return <InstagramSVG {...props}/>
    case 'linkedin': return <LinkedinSVG {...props}/>
    case 'student-job':
    case 'student_job':
    case 'lightning': return <LightningSVG {...props}/>
    case 'internship':
    case 'graduate': return <GraduateSVG {...props}/>
    case 'first_job':
    case 'first-job': return <FirstJobSVG {...props}/>
    case 'trainee': return <TraineeSVG {...props}/>
    case 'tiktok': return <TiktokSVG {...props}/>
    case 'restaurant': return <RestaurantSVG {...props}/>
    case 'totebag-shopping': return <TotebagShoppingSVG {...props}/>
    case 'book': return <BookSVG {...props}/>
    case 'vegetables': return <VegetablesSVG {...props}/>
    case 'event-paid': return <EventPaidSVG {...props}/>
    case 'late-night': return <LateNightSVG {...props}/>
    case 'temp-work': return <TempWorkSVG {...props}/>
    case 'umbrella-beach': return <UmbrellaBeachSVG {...props}/>
    case 'location': return <LocationSVG {...props}/>
    case 'technology': return <TechnologySVG {...props}/>
    case 'settings': return <SettingsSVG {...props}/>
    case 'toolbox': return <ToolboxSVG {...props}/>
    case 'accounting': return <AccountingSVG {...props}/>
    case 'speak': return <SpeakSVG {...props}/>
    case 'law': return <LawSVG {...props}/>
    case 'money': return <MoneySVG {...props}/>
    case 'earth': return <EarthSVG {...props}/>
    case 'public-institution': return <PublicInstitutionSVG {...props}/>
    case 'drive-plan': return <DrivePlanSVG {...props}/>
    case 'starry-eyes': return <StarryEyesSVG {...props}/>
    case 'calendar': return <CalendarSVG {...props}/>
    case 'person': return <PersonSVG {...props}/>
    case 'heart': return <HeartSVG {...props}/>
    case 'psychology': return <PsychologySVG {...props}/>
    case 'play': return <PlaySVG {...props}/>
    case 'cleaning': return <CleaningSVG {...props}/>
    case 'technical': return <TechnicalSVG {...props}/>
    case 'medecine': return <MedecineSVG {...props}/>
    case 'classic-plan': return <ClassicPlanSVG {...props}/>
    case 'envelope': return <EnvelopeSVG {...props}/>
    case 'impact': return <ImpactSVG {...props}/>
    case 'image': return <ImageSVG {...props}/>
    case 'light-bulb': return <LightBulbSVG {...props}/>
    case 'thumbs-up': return <ThumbsUpSVG {...props}/>
    case 'laugh': return <LaughSVG {...props}/>
    case 'fire': return <FireSVG {...props}/>
    case 'rolling-eyes': return <RollingEyesSVG {...props}/>
    default:
        return <path />
    }
}

const getViewBox = (name) => {
    switch (name) {
    case 'left-long-arrow':
        return '0 0 53.28 7.7'
    case 'right-long-arrow':
        return '0 0 53.28 7.7'
    case 'package-0':
        return '0 0 512 512'
    case 'package-1':
        return '0 0 512 512'
    default:
        return '0 0 32 32'
    }
}

const SVGFactory = ({
    name = '',
    style = {},
    fill = 'currentColor',
    viewBox = '',
    width = '100%',
    className = '',
    height = '100%',
    forwardRef = React.createRef(),
    onClick = null,
    skipSvgWrapper = false
}) => {
    let result = null
    if (skipSvgWrapper) {
        result = getPath(name, { fill, width, height })
    } else {
        result = (
            <svg
                width={width}
                ref={forwardRef}
                style={style}
                height={height}
                className={className}
                onClick={onClick}
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewBox || getViewBox(name)}
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {getPath(name, { fill })}
            </svg>
        )
    }
    return result
}

export default SVGFactory
