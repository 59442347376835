import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    companyInfoCardContainer: {
        width: '100%',
        maxWidth: '341px',
        border: `1px solid ${theme.palette.fg.silver}`,
        borderRadius: '20px',
        padding: '32px 23px 32px'
    },

    title: {
        paddingBottom: '4px',
        color: theme.palette.fg.black,
        fontSize: '24px',
        lineHeight: '28px'
    },

    companyPageLinkContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '11px',
        '&:hover $companyPageLinkText': {
            transition: 'all 0.3s ease',
            color: theme.palette.fg.purple
        },
        '&:hover $linkIconContainer': {
            transition: 'all 0.3s ease',
            backgroundColor: theme.palette.bg.purple
        }
    },

    companyPageLinkText: {
        color: theme.palette.fg.black,
        fontSize: '18px',
        lineHeight: '28px'
    },

    linkIconContainer: {
        width: '24px',
        minWidth: '24px',
        height: '24px',
        minHeight: '24px',
        borderRadius: '50px',
        backgroundColor: theme.palette.bg.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))
