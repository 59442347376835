import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './ExperienceProfile_style'
import { Box, Typography, Divider, Input } from '@material-ui/core'

import CollectionHandler from 'assets/CollectionHandler.js'
import ExperienceYearInput from './components/ExperienceYearInput/ExperienceYearInput'
import ExperienceSelectInput from './components/ExperienceSelectInput/ExperienceSelectInput'
import ExperienceCampusSelectInput from './components/ExperienceCampusSelectInput/ExperienceCampusSelectInput'
import ExperienceSchoolInput from './components/ExperienceSchoolInput/ExperienceSchoolInput'
import ExperienceStudyFieldInput from './components/ExperienceStudyFieldInput/ExperienceStudyFieldInput'
import ExperienceTextFieldInput from './components/ExperienceTextFieldInput/ExperienceTextFieldInput'
import ExperienceHighSchoolYearInput from './components/ExperienceHighSchoolYearInput/ExperienceHighSchoolYearInput'

const ExperienceProfileViewCondensed = (props) => {
    const {
        condensed,
        handleChangeStudyCycle,
        handleChangeFormation,
        handleChangeFormationDates,
        handleChangeHighSchoolStudyType,
        handleSchoolChange,
        highSchoolStudyTypeCollection,
        schoolCollection,
        setStudentFormation,
        studentFormation,
        studyFieldCollection
    } = props

    const classes = useStyles()

    const studyCycleCollection = CollectionHandler.Get('studyCycleCollection')
    const studyTypeCollection = CollectionHandler.Get('studyTypeCollection')

    return (
        <Box className={classes.experienceProfileViewCondensedContainer} >
            <Typography variant='h1' component='div' className={classes.title}>
                {condensed
                    ? <FormattedMessage id='landing_experience_profile.studies_condensed' />
                    : <FormattedMessage id='landing_experience_profile.studies' />
                }
            </Typography>
            {studentFormation.filter(experience => experience.deleted === false).map((formation, formationKey) => {
                return (
                    <Box key={formationKey.toString()} item xs={12} display={formation.deleted ? 'none' : 'block'}>
                        <ExperienceSelectInput
                            type='study_cycle'
                            collection={studyCycleCollection}
                            formation={formation}
                            formationKey={formationKey}
                            handleChange={handleChangeStudyCycle(formationKey, 'study_cycle')}
                            textFontClassName={classes.textFont}
                            placeholderTextClassName={classes.inputPlaceholder}
                            placeholderTextId='landing_experience_profile.type_of_cycle'
                            selectClasses={{ selectMenu: classes.input }}
                            iconClassName={classes.chevronIcon}
                            input={<Input classes={{ underline: classes.inputUnderline }} />}
                        />
                        {formation.study_cycle === 'higher_education' && <>
                            <Divider className={classes.fieldsDivider} />
                            <ExperienceSelectInput
                                type='study_type'
                                collection={studyTypeCollection}
                                formation={formation}
                                formationKey={formationKey}
                                handleChange={handleChangeFormation(formationKey, 'study_type')}
                                textFontClassName={classes.textFont}
                                placeholderTextClassName={classes.inputPlaceholder}
                                placeholderTextId='landing_experience_profile.type_of_training'
                                selectClasses={{ selectMenu: classes.input }}
                                iconClassName={classes.chevronIcon}
                                input={<Input classes={{ underline: classes.inputUnderline }} />}
                            />
                            <Divider className={classes.fieldsDivider} />
                            <ExperienceSchoolInput
                                showEndAdornment
                                InputAdornmentClassName={classes.InputAdornment}
                                textFontClassName={classes.textFont}
                                type='study_type'
                                studentFormation={studentFormation}
                                handleSchoolChange={handleSchoolChange}
                                schoolCollection={schoolCollection}
                                formationKey={formationKey}
                                placeholderTextId='landing_experience_profile.establishment'
                                inputClassName={classes.input}
                                inputUnderlineClassName={classes.inputUnderline}
                            />
                            {formation.campuses &&
                                <>
                                    <Divider className={classes.fieldsDivider} />
                                    <ExperienceCampusSelectInput
                                        type='campus'
                                        formation={formation}
                                        formationKey={formationKey}
                                        handleChange={handleChangeFormation}
                                        textFontClassName={classes.textFont}
                                        placeholderTextClassName={classes.inputPlaceholder}
                                        placeholderTextId='landing_experience_profile.campus'
                                        selectClasses={{ selectMenu: classes.input }}
                                        input={<Input classes={{ underline: classes.inputUnderline }} />}
                                    />
                                </>
                            }
                            {formation.school && formation.school.name === 'Other' &&
                                <>
                                    <Divider className={classes.fieldsDivider} />
                                    <ExperienceTextFieldInput
                                        value={formation.other_school}
                                        handleChange={handleChangeFormation(formationKey, 'other_school')}
                                        placeholderTextId='landing_experience_profile.other_establishment'
                                        textFontClassName={classes.textFont}
                                        inputClasses={{ input: classes.input, underline: classes.inputUnderline }}
                                    />
                                </>
                            }
                            <Divider className={classes.fieldsDivider} />
                            <ExperienceStudyFieldInput
                                showEndAdornment
                                InputAdornmentClassName={classes.InputAdornment}
                                textFontClassName={classes.textFont}
                                type='study_type'
                                studentFormation={studentFormation}
                                setStudentFormation={setStudentFormation}
                                studyFieldCollection={studyFieldCollection}
                                formationKey={formationKey}
                                placeholderTextId='landing_experience_profile.field_of_study'
                                inputClassName={classes.input}
                                inputUnderlineClassName={classes.inputUnderline}
                            />
                            {formation.specializationList &&
                                <>
                                    <Divider className={classes.fieldsDivider} />
                                    <ExperienceSelectInput
                                        type='specialization'
                                        collection={formation.specializationList}
                                        formation={formation}
                                        formationKey={formationKey}
                                        handleChange={handleChangeFormation(formationKey, 'specialization')}
                                        textFontClassName={classes.textFont}
                                        placeholderTextClassName={classes.inputPlaceholder}
                                        placeholderTextId='landing_experience_profile.specialization'
                                        selectClasses={{ selectMenu: classes.input }}
                                        iconClassName={classes.chevronIcon}
                                        input={<Input classes={{ underline: classes.inputUnderline }} />}
                                    />
                                </>
                            }
                            {formation.field_of_study && formation.field_of_study.name === 'Other' &&
                                <>
                                    <Divider className={classes.fieldsDivider} />
                                    <ExperienceTextFieldInput
                                        value={formation.other_field_of_study}
                                        handleChange={handleChangeFormation(formationKey, 'other_field_of_study')}
                                        placeholderTextId='landing_experience_profile.other_field_of_study'
                                        textFontClassName={classes.textFont}
                                        inputClasses={{ input: classes.input, underline: classes.inputUnderline }}
                                    />
                                </>
                            }
                            {formation.study_type &&
                                <>
                                    <Divider className={classes.fieldsDivider} />
                                    <ExperienceYearInput
                                        className={classes.date}
                                        value={formation.start_year}
                                        onChange={handleChangeFormationDates(formationKey, 'start_year')}
                                        placeholderTextId='landing_experience_profile.start_date'
                                        inputClasses={{ input: classes.input, underline: classes.inputUnderline }}
                                    />
                                </>
                            }
                            {formation.showEndYear &&
                                <>
                                    <Divider className={classes.fieldsDivider} />
                                    <ExperienceYearInput
                                        className={classes.date}
                                        value={formation.end_year}
                                        onChange={handleChangeFormationDates(formationKey, 'end_year')}
                                        placeholderTextId='landing_experience_profile.end_date'
                                        formattedMessageValues={{ study_type: CollectionHandler.Translate('studyTypeCollection', formation.study_type) }}
                                        inputClasses={{ input: classes.input, underline: classes.inputUnderline }}
                                    />
                                </>
                            }
                        </>}
                        {formation.study_cycle === 'high_school' && <>
                            <Divider className={classes.fieldsDivider} />
                            <ExperienceSelectInput
                                type='study_type'
                                collection={highSchoolStudyTypeCollection}
                                formation={formation}
                                formationKey={formationKey}
                                handleChange={handleChangeHighSchoolStudyType(formationKey, 'study_type')}
                                textFontClassName={classes.textFont}
                                placeholderTextClassName={classes.inputPlaceholder}
                                placeholderTextId='landing_experience_profile.type_of_training'
                                selectClasses={{ selectMenu: classes.input }}
                                iconClassName={classes.chevronIcon}
                                input={<Input classes={{ underline: classes.inputUnderline }} />}
                            />
                            {formation.highSchoolStudyFields && formation.highSchoolStudyFields.length > 0 &&
                                <>
                                    <Divider className={classes.fieldsDivider} />
                                    <ExperienceSelectInput
                                        type='other_field_of_study'
                                        collection={formation.highSchoolStudyFields}
                                        formation={formation}
                                        formationKey={formationKey}
                                        handleChange={handleChangeFormation(formationKey, 'other_field_of_study')}
                                        textFontClassName={classes.textFont}
                                        placeholderTextClassName={classes.inputPlaceholder}
                                        placeholderTextId='landing_experience_profile.specialization'
                                        selectClasses={{ selectMenu: classes.input }}
                                        iconClassName={classes.chevronIcon}
                                        input={<Input classes={{ underline: classes.inputUnderline }} />}
                                    />
                                </>
                            }
                            <Divider className={classes.fieldsDivider} />
                            <ExperienceTextFieldInput
                                value={formation.other_school}
                                handleChange={handleChangeFormation(formationKey, 'other_school')}
                                placeholderTextId='landing_experience_profile.current_establishment'
                                textFontClassName={classes.textFont}
                                inputClasses={{ input: classes.input, underline: classes.inputUnderline }}
                            />
                            <Divider className={classes.fieldsDivider} />
                            <ExperienceHighSchoolYearInput
                                value={formation.high_school_year}
                                formationKey={formationKey}
                                handleChangeFormation={handleChangeFormation}
                                textFontClassName={classes.textFont}
                                placeholderTextClassName={classes.inputPlaceholder}
                                placeholderTextId='landing_experience_profile.current_school_year'
                                selectClasses={{ selectMenu: classes.input }}
                                iconClassName={classes.chevronIcon}
                                input={<Input classes={{ underline: classes.inputUnderline }} />}
                            />
                        </>}
                    </Box>
                )
            })}
        </Box >
    )
}

export default ExperienceProfileViewCondensed
