import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from '../CreditPackages_style'
import { UserContext } from 'contexts/UserContext'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import RouteMap from 'assets/RouteMap'
import Button from 'shared/components/Button/Button'
import ItemIcon from 'components/ItemIcon/ItemIcon'

const FreeCreditPackage = (props) => {
    const classes = useStyles()
    const userContext = useContext(UserContext)
    const registerForFreeCreditsUrl = userContext?.user
        ? RouteMap.Page('employer/new-ad')
        : `${RouteMap.Page('login')}?userType=employer`

    const sendAnalyticsClickFreeJobs = () => {
        if (!userContext) AnalyticsHelper.sendGTMEvent('Clicked Publish Jobs Free')
    }

    const redirectToRegisterLogin = () => {
        window.location.href = registerForFreeCreditsUrl
    }

    const handleClickFreejobs = () => {
        if (!!userContext?.user) return

        sendAnalyticsClickFreeJobs()
        redirectToRegisterLogin()
    }

    return (
        <Box className={classes.packageCard}>
            <Box className={classes.infoSection}>
                <Box className={classes.cardIcon}>
                    <ItemIcon type='student_job' width={40} height={40} />
                </Box>
                <Typography variant='h6' component='h2' color='primary' className={`${classes.cardTitle} ${classes.uppercase} ${classes.withMinHeight}`}>
                    <FormattedMessage id='credit_packages.try_free' />
                </Typography>
                <Box className={classes.creditFeatures}>
                    <Typography className={classes.creditFeature}><FormattedMessage id='credit_packages.features.new' /></Typography>
                    <Typography className={classes.creditFeature}><FormattedMessage id='credit_packages.features.90_days' /></Typography>
                    <Typography className={classes.creditFeature}><FormattedMessage id='credit_packages.features.1_credit' /></Typography>
                    <Typography className={classes.creditFeature}><FormattedMessage id='credit_packages.features.credits_valid_4_months' /></Typography>
                </Box>
            </Box>
            <Box className={classes.CTASection}>
                <Typography variant='h6' component='p' color='primary' className={classes.cardCTATitle}>
                    <FormattedMessage id='credit_packages.new_user' />
                </Typography>
                <Box className={classes.cardButton}>
                    <Button
                        variant='cta-primary'
                        forceWrap
                        onClick={handleClickFreejobs}
                        className={`${classes.button} ${!!userContext?.user && classes.buttonDisabled}`}
                    >
                        <FormattedMessage id='credit_packages.publish_free' />
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default FreeCreditPackage
