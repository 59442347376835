import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    similarItemsContainerWrapper: {
        width: '100%',
        paddingTop: '90px',
        paddingBottom: '66px',
        backgroundColor: theme.palette.bg.blue,
        backgroundImage: "url('https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png')",
        [theme.breakpoints.down('md')]: {
            padding: '60px 28px'
        },
    },
    similarItemsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 60,
        [theme.breakpoints.down('md')]: {
            gap: 40
        },
    },
    title: {
        textAlign: 'center',
        color: theme.palette.fg.black,
        fontFamily: 'GreedWide-Heavy',
        fontSize: '40px',
        lineHeight: '50px',
        marginBottom: '42px',
        textTransform: 'uppercase',
        [theme.breakpoints.down('md')]: {
            fontSize: '32px',
            lineHeight: '40px',
            marginBottom: '36px'
        }
    },
    cardsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 40,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
    },
    companyCardBox: {
        maxWidth: 400,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
    }
}))
