import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from '../CreditPackages_style'
import Button from 'shared/components/Button/Button'
import CollectionHandler from 'assets/CollectionHandler'
import ItemIcon from 'components/ItemIcon/ItemIcon'

const FirstJobCreditPackage = (props) => {
    const classes = useStyles()
    const { handlePayment } = props
    const creditPackagesCollection = CollectionHandler.Get('creditPackagesCollection')
    const [firstJobPackage, setFirstJobPackage] = useState({})

    useEffect(() => {
        const packageFirstJob = creditPackagesCollection.find(creditPackage => creditPackage.name.includes('firstjob'))
        setFirstJobPackage(packageFirstJob)
    }, [])

    const handleClick = () => {
        handlePayment(firstJobPackage)
    }

    return (
        <Box className={classes.packageCard}>
            <Box className={classes.infoSection}>
                <Box className={classes.cardIcon}>
                    <ItemIcon type='first_job' width={40} height={40} />
                </Box>
                <Typography variant='h6' component='h2' color='primary' className={`${classes.cardTitle} ${classes.uppercase} ${classes.withMinHeight}`}>
                    <FormattedMessage id='credit_packages.first_job' />
                </Typography>
                <Box className={classes.creditFeatures}>
                    <Box className={classes.creditFeature}>
                        <Typography><FormattedMessage id='credit_packages.features.1_first_job_credit' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <Typography><FormattedMessage id='credit_packages.features.40_days' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <Typography><FormattedMessage id='credit_packages.features.head_of_list' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <Typography><FormattedMessage id='credit_packages.features.personalised_company_page' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <Typography><FormattedMessage id='credit_packages.features.integration_job_alerts' /></Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.CTASection}>
                <Typography variant='h6' component='p' color='primary' className={classes.cardCTATitle}>
                    <FormattedMessage id='credit_packages.total_value' values={{ value: firstJobPackage.value }} />
                </Typography>
                <Box className={classes.cardButton}>
                    <Button
                        variant='cta-primary'
                        forceWrap
                        onClick={handleClick}
                        className={classes.button}
                    >
                        <FormattedMessage id='credit_packages.buy' />
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default FirstJobCreditPackage
